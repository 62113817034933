<template>
  <div style="display:flex" id="ASPersonDiv" :class="colorborderclass(personStore.active_data.type)" role="button"
    @click="$emit('changeeditor', 'personenEdit')">

    <h4 v-if="personStore.active_data.asksam" className='pr-2 float-left font-semibold'>Kennnummer:</h4>
    <p className='mb-4'>{{ personStore.active_data.asksam }}</p>
    <p className='mb-4'>{{ personStore.active_data.idno }}</p>


    <h4 v-if="personStore.active_data.name.forname" className='pr-2 float-left font-semibold'>Vorname:</h4>
    <p className='mb-4'>{{ personStore.active_data.name.forname }}</p>

    <h4 v-if="personStore.active_data.name.surnames[0].text" className='pr-2 float-left font-semibold'>Familienname:</h4>
    <p className='mb-4'>{{ personStore.active_data.name.surnames[0].text }}</p>

    <div v-for="(variante, index) in personStore.active_data.name.surnames" v-bind:key="variante">
      <span className='pr-2 float-left font-semibold' v-if="index == 0">Varianten:</span>
      <p  className='mb-4'>
        <span v-if="variante.type !== 'original' || index > 0">{{ variante.text }}</span>
        <span v-if="variante.type !== 'original'"> (Normalisiert
          <span v-if="variante.type === 'place'"> nach Ort</span>
          <span v-if="variante.type === 'prof'"> nach Handwerk</span>)
        </span>
      </p>
    </div>

    <h4 v-if="personStore.active_data.name.familie " className='pr-2 float-left font-semibold'>Kölner Geschlechter:</h4>
    <p className='mb-4'>{{ personStore.active_data.name.familie }}</p>

    <h4 v-if="personStore.active_data.name.namenote" className='pr-2 float-left font-semibold'>Anmerkung(Name):</h4>
    <p className='mb-4'>{{ personStore.active_data.name.namenote }}</p>


    <h4 v-if="personStore.active_data.label" className='pr-2 float-left font-semibold'>Bezeichnung:</h4>
    <p className='mb-4'>{{ personStore.active_data.label }}</p>

    <h4 className='pr-2 float-left font-semibold'>Nachgewiesen: </h4>
    <span className='mb-4'>{{ personStore.activeFromDate }} - {{ personStore.activeToDate }}</span>


    <!-- Andere Verwandte hinzufügen (Kinder, Großeltern, ...) -->
    <div v-if="personListStore.personlist.length>0">
      <div class="grid grid-cols-1" v-for="(value, key) in {parent: 'Eltern', sibling: 'Geschwister', child: 'Kinder', spouse:'Ehepartner'}" :key="key">
        <div class="grid grid-cols-1" v-if="personStore.getActiveRelationsOverType(key).length > 0">
          <h4 className='pr-2 float-left font-semibold'>{{value}}: </h4>
          <p v-for="(relation, index) in personStore.getActiveRelationsOverType(key)" v-bind:key="relation" className='mb-4'>
            <a href="">{{ index + 1 }}. {{relation.name}} ({{relation.idno}})</a>
          </p>
        </div>
      </div>
    </div>

    <h4 v-if="personStore.active_data.relnotes" className='pr-2 float-left font-semibold mt-4'>Notizen zur Verwandtschaft:</h4>
    <p className='mb-4'>{{ personStore.active_data.relnotes }}</p>

    <h4 v-if="personStore.active_data.note" className='pr-2 float-left font-semibold'>Notizen zur Person:</h4>
    <p className='mb-4'>{{ personStore.active_data.note }}</p>



  </div>
</template>
<script>

import {mapState} from "pinia";

import {personListStore} from "@/stores/personList";
import {personStore} from "@/stores/person";

export default {
  name: "PreviewPerson",
  emits: ['changeeditor'],
  computed: {
    personListStore: function (){ return personListStore(); },
    ...mapState(personListStore,[
      'personlist'
    ]),

    personStore: function (){return personStore();},
    ...mapState(personStore, [
       'active_data'
    ]),
  },

  methods: {
    colorborderclass(type) {
      let classstring = 'hover:bg-gray-200  mr-10 mb-10 rounded-md p-4 border-2 flex flex-col '
      if (type === 'begine') { classstring += 'border-teal-600' }
      else { classstring += 'border-cyan-500' }
      return classstring
    },
  }
}


</script>
<style scoped></style>