<template>
  <div>
    <h3>Regest {{regestenStore.getIndexOfRegestInListOverID(regestid)+1}} :</h3>
    <div class="row-start-2 row-span-3">
        <div>
          <span v-if="regestIsEqual.date !== true">Datum</span>
          <span v-if="regestIsEqual.bibl !== true">Bibliographie</span>
          <span v-if="regestIsEqual.text !== true">Regestentext</span>
          <span v-if="regestIsEqual.persons !== true">Personen</span>
          <span v-if="regestIsEqual.places !== true">Häuser</span>
          <span v-if="regestIsEqual.convents !== true">Konvente</span>
          <span v-if="regestIsEqual.regnotes !== true">Notizen zum Regest</span>
        </div>

    <LoginTemplate v-if="showlogin" :loginmsg="'Fehler beim Speichern bitte einloggen und erneut versuchen'"
                   @login="relogged"></LoginTemplate>

    </div>

    <div >
      <button @click="saveRegest"
              className="hover:bg-red-200 outline-none mr-4 border-2 rounded-md border-red-600 p-1 mt-auto mb-auto w-20">Speichern</button>

      <button @click="saveDone"
              className="hover:bg-red-200 outline-none mr-4 border-2 rounded-md border-red-600 p-1 mt-auto mb-auto w-20">Ingorieren</button>
    </div>
  </div>
</template>

<script>
import {regestenStore} from "@/stores/regesten";
import {mapState} from "pinia";
import LoginTemplate from "@/components/popuptemplates/LoginTemplate.vue";


export default {
  name: "SaveRegest.vue",
  components: {LoginTemplate},
  emits: ['done'],
  props: ['regestid', 'save' ],
  data() {
    return {
      saveregestdatadone: false
    }

  },

  computed:{
    regestenStore: function(){
      return regestenStore();
    },
    ...mapState(regestenStore, [
      'old_regestenList',
      'active_id',
      'active_data'
    ]),
    regestIsEqual: function(){
      return this.regestenStore.compareRegestenData(this.regestenStore.getRegestDataFromListOverID(this.regestid), this.regestenStore.getOldRegestData(this.regestid));
    }


  },


  watch: {
    save(newvalue){
      if(newvalue === true){ this.saveRegest(); }
    },

    saveregestdatadone(newvalue){
      if(newvalue === true){
        this.saveDone()
      }
    },

    saveall(newvalue){
      if(newvalue === true){
        this.saveRegest();
      }
    }
  },

  methods:{
    saveRegest: async function (){
      this.saveregestdatadone = await this.regestenStore.saveRegestData(this.regestenStore.getRegestDataFromListOverID(this.regestid));
    },

    saveDone: function (){this.$emit("done");},

    /*

    compare(){
      //const saveresponses = [];
      const saveregests = []
      for (const newregest of JSON.parse(JSON.stringify(this.regestenData))){
          let newid = newregest.id
          let oldregest = JSON.parse(JSON.stringify(this.oldregestData.find(obj => obj.id === newid)))
          saveregests.push(this.compareregest(newregest, oldregest))
      }
      Promise.all(saveregests).then((results) =>{
        console.log(results)
        this.savedone()
      }).catch(err => {
        console.log(err)
      })
    },



   compareregest(newregest, oldregest){
    if(!_.isEqual(newregest, oldregest)){
      const data = newregest
      return axios.request({
        method: 'post',
        url: this.httpurl + '/update/regest',
        headers:{
          "pass" : sessionStorage.getItem('pass'),
          "name" : sessionStorage.getItem('userName')
        },
        data: data,
      }).then((response) =>{
        console.log(response)
        return {'id': data.id, 'status': 'changed', 'response': response}
      })
    }
  },

     */


},

}
</script>


<style scoped>

</style>