<template>
    <div id="addbeginepopup"
        className="fixed top-[25%] left-[35%] z-20 bg-gray-100 border-teal-600 rounded border-2 w-1/3 h-1/3 pl-4 pt-4">
        <h3 className='mt-auto mb-auto font-semibold'>Neue Begine:</h3>
        <div className=" grid grid-cols-2 grid-flow-row gap-4">
            <label for='newname' className='mt-auto mb-auto font-semibold'>Name:</label>
            <input name="newname" type="text" className='mr-4 border-2 rounded-md border-gray-300 p-1 mt-auto mb-auto' />
            <label for="newnumber" className='mt-auto mb-auto font-semibold'>Nummer:</label>
            <input name="newnumber" type="text" className='mr-4 border-2 rounded-md border-gray-300 p-1 mt-auto mb-auto' />
            <button type="button" v-on:click="closePopup()"
                className="outline-none mr-4 border-2 rounded-md border-teal-600 p-1 mt-auto mb-auto hover:bg-gray-200">NEUE
                BEGINE ANLEGEN</button>
            <!--v-on:click="relations()"-->

        </div>

    </div>


    <div v-on:click="closePopup()" id="black-bg"
        className="bg-black top-0 left-0 w-full h-full z-10 opacity-80 overflow-visible fixed">
    </div>
</template>
<script>
export default {
    name:"AddBegineTemplate",
    props: [],
    data(){

    },
    methods: {
        closePopup() {
            this.$emit("close-popup");
        },
    }
}</script>
<style scoped></style>