import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import axios from 'axios'
import VueAxios from "vue-axios"
import router from './router'


let app = createApp(App)

const pinia = createPinia()

const ip = location.host.substring(0,location.host.indexOf(':'))
app.config.globalProperties.httpurl = process.env.NODE_ENV === 'production'
  ? '/api'
  : 'http://'+ip+':8080'

const httpurl = app.config.globalProperties.httpurl

export {httpurl}



app.use(VueAxios, axios).use(pinia).use(router).mount('#app')