//third-party
import { defineStore } from 'pinia'
import {httpurl} from "@/main";
import axios from "axios";
import _ from 'lodash';

// stores
import {personListStore } from "@/stores/personList";

export const personStore = defineStore('person',{
    state: function (){
        return{
        /*******************************************************
                Data about actual in Editor editable Person
        *******************************************************/
            active_data: null,
            active_id: null,

        /*******************************************************
                Last in Editor Active Persondata
        *******************************************************/
            prev_data: null,
            prev_id: null,

        /*******************************************************
                Temporary Data (Delete after each use)
                example: Data about Person in
                ../componentes/AddTPersonTemplate.vue
        *******************************************************/
            temp_data: null,
            temp_id: null,

        /*******************************************************
                Data like they are in the DB at loading Time.
        *******************************************************/
            old_data: null,


        /*******************************************************
                Variables to track save status
         *******************************************************/
            savepersondatadone: false,
            saverelationsdone:  true,
            deleterelationsdone: false,
            savesameasdone: false,
            saveerror: null,

        }

    },

    actions:{
        /*******************************************************
                RESt-API calls
         *******************************************************/
        callLoadPersonData: async function(id) {
            const url = httpurl + '/person/id/' + id
            const response = await axios.get(url,  {
                headers: {
                    "Content-Type": "application/json",
                }
            });
            return await response.data
        },

        callPost: async function(url, data){
           return axios.post(url, data, {
                headers: {
                    "pass": sessionStorage.getItem('pass'),
                    "name": sessionStorage.getItem('userName')
                }
            });
        },

        callSavePersonData: async function(data){
            const personliststore = personListStore();
            await this.callPost(httpurl+'/new/person', data).then((response) => {
                this.temp_data = response.data;
                console.log(this.temp_data);
                personliststore.addPersonToList(response.data);
            });
        },

        callUpdatePersonData: async function() {
            let newpersdata = JSON.parse(JSON.stringify(this.active_data));
          //  delete newpersdata.relations;
            const data = newpersdata;
            console.log(data)
            await this.callPost(httpurl+'/update/person', data).then(response =>{
                if("error" in response.data){ this.saveerror = true; }
                this.savepersondatadone = true;
            });
        },

        callSaveSameAs: async function () {
            console.log("start sameassave")
            const data = {"sameasid": this.personData.sameas.id, "persid": this.personData.id}
            await this.callPost(httpurl+'/update/sameas', data).then(response =>{
                if("error" in response.data){this.saveerror = true;}
                this.savesameasdone = true;
            })
        },

        /*
        callSaveNewRelation: function (data){
            this.callPost(httpurl + '/update/person/relation', data).then(response =>{
                if('error' in response.data){
                    this.saveerror = true;
                    return { 'id': data.id, 'status': 'error', 'response': response};
                }
                else {
                    return {'id': data.id, 'status': 'changed', 'response': response}
                }
            })
        },

        callDeleteRelation: async function(relationref){
            const data = {
                persid: this.active_data.id,
                relationid: relationref
            };
            this.callPost(httpurl + '/delete/person/relation', data).then(
                response => {
                    if('error' in response.data){
                        this.saveerror = true;
                        return { 'id': relationref, 'status': 'error', 'response': response};
                    }
                    else { return {'id': relationref, 'status': 'deleted', 'response': response}; }
                });
        },
         */
        /*******************************************************
                general methods for all kind of PersonData:
                            active_ prev_ temp_
        *******************************************************/

        saveData() {
                this.callUpdatePersonData()
            //    this.compareRelations()
                this.compareSameAs()
            },

        dataIsEqual: function (a, b){
            if(_.isEqual(a, b)){
                console.log("Data ist gleich");
                return true;
            }
            else{
                console.log("Data ist UNGLEICH");

                return {
                    name: _.isEqual(a.name, b.name),
                    label: _.isEqual(a.label, b.label),
                    relations: _.isEqual(a.relations, b.relations),
                    relnotes: _.isEqual(a.relnotes, b.relnotes),
                    sameas: _.isEqual(a.sameas, b.sameas),
                    state: _.isEqual(a.state, b.state),
                }
            }
        },
        /*
        compareRelations: async function() {
            let newrelations = this.active_data.relations;
            let oldrelations = this.old_data.relations;
            this.deleteRelations(newrelations, oldrelations);
            this.newRelations(oldrelations);
        },
        */
        compareSameAs: function(){
            if(!_.isEqual(this.active_data.sameas, this.old_data.sameas)){
                console.log("same as ist anders");
                this.callSaveSameAs();
            }
            else{ this.savesameasdone = true; }
        },




        deleteRelations: async function(newrelations, oldrelations){
            const deleterelations = [];
            for (const relation of oldrelations){
                console.log(relation.id);
                console.log(newrelations.some(rel => rel.id === relation.id));
                if(!newrelations.some(rel => rel.id === relation.id)){
                    deleterelations.push(this.callDeleteRelation(relation.id));
                }
            }
            Promise.all(deleterelations).then((results) =>{
                console.log("await deleted relations")
                console.log(results);
                this.deleterelationsdone = true;
            });
        },


        /*
        newRelations: async function(oldrelations){
            console.log("New relations save")
            const saverelations = [];
            for (const newrelation of JSON.parse(JSON.stringify(this.active_data.relations))) {
                console.log(newrelation);
                if (oldrelations.some(relation => relation.id === newrelation.id))
                {
                    let oldrelation = oldrelations.find(relation => relation.id === newrelation.id);
                    if (!_.isEqual(oldrelation, newrelation)) {
                        const data = {'persid': this.active_data.id, 'relation': newrelation};
                        saverelations.push( this.callSaveNewRelation(data) );
                    }
                }
                else{
                    const data = {'persid': this.active_data.id, 'relation': newrelation};
                    saverelations.push( this.callSaveNewRelation(data) );
                }
            }
            Promise.all(saverelations).then((results) =>{
                console.log("await saved relations")
                console.log(results);
                this.saverelationsdone = true;
                console.log(this.saverelationsdone)
            });
        },
         */

        removeRelation: function(index) {
            this.active_data.relations.splice(index, 1)
        },

        resetAllData: function(){
            this.resetTempData();
            this.resetActiveData();
            this.resetOldData();
            this.resetSaveStates();
        },

        resetSaveStates: function (){
            this.savepersondatadone = false;
            this.saverelationsdone = false;
            this.deleterelationsdone = false;
            this.savesameasdone = false;
        },

        /*******************************************************
                methods for active_ PersonData:
        *******************************************************/

        getActiveRelationsOverType: function (type){
            const personliststore = personListStore();
            let relatives = this.active_data.relations.filter(obj => { return obj.type === type });
            let relations = [];

            relatives.forEach((relative) => relations.push(personliststore.getPersonOverId(relative.id)) );
            return relations
        },

        /** load a new active Person into active_data,  **/
        setActiveData: async function() {
            if(this.active_id) {
                this.active_data = await this.callLoadPersonData(this.active_id);
                this.setOldData(this.active_data);
            }
            else{console.log("can't load active_data, no active_id available");}
        },

        setActiveId: function(id) { this.active_id = id; },

        resetActiveData: function(){
          this.active_data = null;
          this.active_id = null;
          },



        /*******************************************************
                    methods for prev_ PersonData
        *******************************************************/

        setPrevId: function(id){this.prev_id = id},

        /*******************************************************
                    methods for temp_ PersonData
        *******************************************************/

        getTempData: async function(id) { this.temp_data = await this.callLoadPersonData(id) },

        setTempData: function(data){ this.temp_data = data; },

        resetTempData: function(){ this.temp_data = null; },

        setTempPersonID: function(id){ this.temp_id = id; },

        saveNewTempData: async function(){ await this.callSavePersonData(this.temp_data);
            return this.temp_data;},

        /*******************************************************
                    methods for old_ PersonData
         *******************************************************/

        setOldData: function (json) { this.old_data = JSON.parse(JSON.stringify(json)); },

        resetOldData: function (){this.old_data = null;},

    },
    getters:{
        /*******************************************************
                    getters for active_ PersonData
         *******************************************************/
        activeFromDate: function() {
            let splitted = this.active_data.active.from.split('-');
            return splitted[2] + '.' + splitted[1] + '.' + splitted[0];
        },

        activeToDate: function () {
            let splitted = this.active_data.active.to.split('-');
            return splitted[2] + '.' + splitted[1] + '.' + splitted[0];
        },

        activeParentsArray: function () {
            const personliststore = personListStore();
            let relatives = this.active_data.relations.filter(obj => { return obj.type === "parent" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names
        },

        activeSiblingsArray: function () {
            const personliststore = personListStore();
            let relatives = this.active_data.relations.filter(obj => { return obj.type === "sibling" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names
        },

        activeChildArray: function () {
            const personliststore = personListStore();
            let relatives  = this.active_data.relations.filter(obj => { return obj.type === "child" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names
        },

        activeSpouseArray: function () {
            const personliststore = personListStore();
            let relatives = this.active_data.relations.filter(obj => { return obj.type === "spouse" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names
        },
        /*******************************************************
                    getters for prev_ PersonData
         *******************************************************/

        /*******************************************************
                    getters for temp_ PersonData
         *******************************************************/
        tempFromDate: function() {
            let splitted = this.temp_data.active.from.split('-');
            return splitted[2] + '.' + splitted[1] + '.' + splitted[0];
            },

        tempToDate: function () {
            let splitted = this.temp_data.active.to.split('-');
            return splitted[2] + '.' + splitted[1] + '.' + splitted[0];
            },

        tempParentsArray: function () {
            const personliststore = personListStore();
            let relatives = this.temp_data.relations.filter(obj => { return obj.type === "parent" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names
        },

        tempSiblingsArray: function () {
            const personliststore = personListStore();
            let relatives = this.temp_data.relations.filter(obj => { return obj.type === "sibling" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names
            },

        tempChildArray: function () {
            const personliststore = personListStore();
            let relatives  = this.temp_data.relations.filter(obj => { return obj.type === "child" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names },

        tempSpouseArray: function () {
            const personliststore = personListStore();
            let relatives = this.temp_data.relations.filter(obj => { return obj.type === "spouse" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names},

    }
})