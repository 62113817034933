
export const legalterms = {
    none: "-",
    Verkauf: "Verkauf/Veräußerung",
    Vergleich: "Vergleich",
    Übertrag: "Übertragung",
    Erbgang: "Erbgang",
    Gelübde: "Gelübde",
    Rente: "Rente",
    Leibrente: "Leibrente",
    Verpachtung: "Verpachtung",
    Verzicht: "Verzicht",
    Schenkung: "Schenkung",
    Lageangabe: "Lageangabe",
    Besitzbestätigung: "Besitzbestätigung",
    Schoeffenspruch: "Schöffenspruch",
    Stiftung: "Stiftung",
    Eidesleistung: "Eidesleistung",
    Anschreinung: "Anschreinung",
    Vollmacht: "Vollmacht",
    Erbleihe: "Erbleihe",
    Legat: "Legat",
};

export const sourcetypes ={
    none: 'Quellenart',
    schrb: 'Schreinsbücher, Best. 101',
    schreinskarte: 'Schreinskarten, Best. 100',
    schreins_urkunde: 'Schreinsurkunden, Best. 102',
    schr_urkunden_col: 'Columba, Best. 103',
    hua_urkunde: 'Haupturkundenarchiv, Best. 1',
    av_urkunde: 'Armenverwaltung Urkunden, Best. 160',
    av_buecher: 'Armenverwaltung Bücher, Best. 160',
    testament: 'Testamente, Best. 110',
    ursula_urkunden: 'Ursula Urkunden, Best. 266',
    min_urkunden: 'Minoriten Urkunden, Best. 255',
    dom_urkunden: 'Domstift Urkunden, Best. 210',
    'domstift_rep_u_hand.': 'Domstift Repertorien und Handschriften, Best. 210',
    karmel: 'Karmeliter, Best. 228',
    geistl_abt: 'Geistliche Abteilung, Best. 295',
    andreas_urkunden: 'Andreas Urkunden, Best. 201',
    georg_urkunden: 'Georg Urkunden, Best. 214',
    weiher_urkunden: 'Weiher Urkunden, Best. 270',
    katharina_urkunden: 'Katharina Urkunden, Best. 234',
    aposteln_urkunden: 'Aposteln Urkunden, Best. 204',
    gereon_urkunden: 'Gereon Urkunden, Best. 215',
    agatha_urkunden: 'Agatha Urkunden, Best. 200',
    apern_urkunden: 'Apern Urkunden, Best. 293',
    luecker_sammlung: 'Sammlung Lückger, Best. 1161',
    alfter_sammlung: 'Sammlung Alfter, Best. 1001'
};

export const districts = {
    Unbekannt: 'Unbekannt',
    Martin: 'I Martin',
    Brigida: 'II Brigida',
    Unterlan: 'III Unterlan',
    Alban: 'IV Alban',
    Laurenz: 'V Laurenz',
    Peter: 'VI Peter',
    Columba: 'VII Columba',
    Aposteln: 'VIII Aposteln',
    Niederich: 'IX Niederich',
    Airsbach: 'X Airsbach',
    Christoph: 'XI Christoph',
    Weyer: 'XII Weyer',
    Severin: 'XIII Severin',
    Eigelstein: 'XV Eigelstein',
    Hacht: 'XVI Hacht',
    Dilles: 'XVIII Dilles',
    Mühlenschrein: 'XIX Mühlenschrein',
    Schöffenschrein: 'XX Schöffenschrein'
};

export const districtnr = {
    0: 'Unbekannt',
    1: 'Martin',
    2: 'Brigida',
    3: 'Unterlan',
    4: 'Alban',
    5: 'Laurenz',
    6: 'Peter',
    7: 'Columba',
    8: 'Aposteln',
    9: 'Niederich',
    10: 'Airsbach',
    11: 'Christoph',
    12: 'Weyer',
    13: 'Severin',
    15: 'Eigelstein',
    16: 'Hacht',
    18: 'Dilles',
    19: 'Mühlenschrein',
    20: 'Schöffenschrein'
};


export const datetypes = {
         when: 'Zeitpunkt',
         fromto: 'Zeitspanne',
         none: '-'
    };

export const families = {
    Familie_auswaehlen: ' ',
    Overstolz: 'Overstolz',
    Lyskirchen: 'Lyskirchen',
    Dracone: 'Dracone',
    Von_der_Kornpforte: 'Von der Kornpforte',
    Von_der_Ulrepforte: 'Von der Ulrepforte',
    Vom_Pallas: 'Vom Pallas',
    Buntbart: 'Buntbart',
    Schoneweder: 'Schoneweder',
    Vom_Ufer: 'Vom Ufer',
    Von_der_Aducht: 'Von der Aducht',
    Birkelin: 'Birkelin',
    Vom_Horne: 'Vom Horne',
    Windeck: 'Windeck',
    Cause: 'Cause',
    Kleingedank: 'Kleingedank',
    Gir: 'Gir',
    Grin: 'Grin',
    Hardevust: 'Hardevust',
    Hirzelin: 'Hirzelin',
    De_Lobio: 'De Lobio',
    Morart: 'Morart',
    Vom_Neumarkt: 'Vom Neumarkt',
    Vom_Hirtz: 'Vom Hirtz',
    Von_der_Landskrone: 'Von der Landskrone',
    Jude: 'Jude',
    Vom_Kusin: 'Vom Kusin',
    Von_der_Lintgasse: 'Von der Lintgasse',
    Benesis: 'Benesis',
    Lintlar: 'Lintlar',
    Von_der_Mühlengasse: 'Von der Mühlengasse',
    Weise: 'Weise',
    Von_der_Pforte: 'Von der Pforte',
    Mommersloch: 'Mommersloch',
    Van_der_Poe: 'Van der Poe',
    Quattermart: 'Quattermart',
    Scherfgin: 'Scherfgin',
    Schiderich: 'Schiderich',
    Von_der_Trankgasse: 'Von der Trankgasse',
    Schuren: 'Schuren',
    Vom_Spiegel: 'Vom Spiegel',
    Vom_Stave: 'Vom Stave',
    De_Trappa: 'De Trappa'
};