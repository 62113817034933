<template>

  <div className='flex flex-row pt-5 pb-5'>
    <div className='h-full flex flex-col pl-24'>
      <div id="ASPersonDiv" className="mr-10 mb-10 rounded-md p-4 border-2 border-teal-600 whitespace-pre-line">
        <h3 className="font-bold">AskSam ID</h3>
        <p>{{this.asksamnr}}</p>
        <h3 className="font-bold">Vorname</h3>
        <p>{{this.vorname}}</p>
        <h3 className="font-bold">Nachname</h3>
        <p>{{this.famname}}</p>
        <h3 className="font-bold">Bezeichnung</h3>
        <p>{{this.bez}}</p>
        <h3 className="font-bold">Daten</h3>
        <p>{{this.daten}}</p>
        <h3 className="font-bold">Verwandte</h3>
        <p>{{this.verwandte}}</p>
        <h3 className="font-bold">Notes</h3>
        <p>{{this.notizen}}</p>
      </div>
      <h3 className="font-bold pb-2">Regesten:</h3>
      <div v-for="quelle in this.quellen" :key='quelle' className="mr-10 rounded-md p-4 mb-2 border-2 border-teal-600">
        
        <p>{{quelle}}</p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "AskSamView",
  data(){ return {
    nr: "",
    mode: 'regestenEdit'
  }},
  props:['asksamData'],
  methods: {
        changeEditor: function(mode) {
            this.mode = mode
            this.$emit('changeeditor',mode,)
        }
    },




  computed:{
    asksamnr() {return this.asksamData.nr},
    vorname(){return this.asksamData.vorname},
    famname(){return this.asksamData.famName},
    bez(){return this.asksamData.bez},
    daten(){return this.asksamData.daten},
    verwandte(){return this.asksamData.verwandte},
    quellen(){return this.asksamData.quellen},
    notizen(){return this.asksamData.notizen}

  },

  }
</script>

<style scoped>

</style>