<template>
  <div id="addrelationpopup"
    className="fixed top-[15%] left-[35%] z-20 bg-gray-100 border-teal-600 rounded border-2 w-1/3 h-3/4 lg:h-fit p-4 overflow-scroll">
    <h3 className='mt-auto mb-auto font-semibold'>Neue Person:</h3>
    <div className=" grid grid-cols-2 grid-flow-row gap-4 mt-4">

      <input v-if="shownewperson == false" name="newname" type="text" className='mr-4 border-2 rounded-md border-gray-300 p-1 mt-auto mb-auto'
        @input="personListStore.searchPersonList(personsearchstring)" v-model="personsearchstring" />
      <div id="autocompleteresult" className="max-h-24 overflow-scroll col-start-2">
        <ul>
          <li @click="selectPerson($event)" className="hover:bg-gray-200" v-for="(person, index) in personsearchresult"
            :key="person" v-bind:id="'personresult_' + index" v-bind:data-asksamnr="person.entrynr"
            v-bind:data-id="person.id" v-bind:data-name="person.name">
            <template v-if="person.type == 'main'"><b @click="selectPerson($event)" v-bind:data-asksamnr="person.entrynr"
                                                      v-bind:data-id="person.id" v-bind:data-name="person.name" >{{ person.name }} ({{person.entrynr }})</b></template>
            <template v-else>{{ person.name }} ({{person.nr }}) {{person.idno}}</template>

          </li>
        </ul>
      </div>
      <div v-if="personStore.temp_data && shownewperson == false" class="border-y-2 border-teal-600 col-span-2 py-4">

        <h4 className='pr-2 float-left font-semibold'>Kennnummer:</h4>
        <p className='mb-4'>{{ personStore.temp_data.asksam }}</p>


        <h4 className='pr-2 float-left font-semibold'>Vorname:</h4>
        <p className='mb-4'>{{ personStore.temp_data.name.forname }}</p>


        <h4 className='pr-2 float-left font-semibold'>Familienname:</h4>
        <p v-for="(variante) in personStore.temp_data.name.surnames" v-bind:key="variante" className='mb-4'>{{ variante.text }}</p>

        <h4 className='pr-2 float-left font-semibold'>Bezeichnung:</h4>
        <p className='mb-4'>{{ personStore.temp_data.label }}</p>

        <h4 className='pr-2 float-left font-semibold'>Daten:</h4>
        <p className='mb-4'>Nachgewiesen von: {{ personStore.tempFromDate }} - {{ personStore.tempToDate }}</p>

        <h3 class="mb-2">Verwandte: </h3>
        <h4 className='pr-2 float-left font-semibold'>Eltern:</h4>
        <br>
        <p v-for="(name, index) in personStore.tempParentsArray" :key="name" className='mb-4'><a href="">{{ index + 1 }}.
            {{ name }}</a></p>

        <h4 className='pr-2 float-left font-semibold'>Geschwister: </h4>
        <br>
        <p v-for="(name, index) in personStore.tempSiblingsArray" :key="name" className='mb-4'><a>{{ index + 1 }}.
            {{ name }}</a></p>

        <h4 className='pr-2 float-left font-semibold'>Kinder:</h4>
        <br>
        <p v-for="(name, index) in personStore.tempChildArray" :key="name" className='mb-4'><a>{{ index + 1 }}.
            {{ name }}</a></p>

        <h4 className='pr-2 float-left font-semibold'>Ehepartner:</h4>
        <br>
        <p v-for="(name, index) in personStore.tempSpouseArray" :key="name" className='mb-4'><a>{{ index + 1 }}.
            {{ name }}</a></p>

      </div>
      <div v-else-if="personStore.temp_data && shownewperson == true">
        <h4 className='pr-2 float-left font-semibold'>Vorname:</h4>
        <input className='mb-4' v-model="personStore.temp_data.forname"/>


        <h4 className='pr-2 float-left font-semibold'>Familienname:</h4>
        <input className='mb-4' v-model="personStore.temp_data.surname"/>
      </div>

      <span class="row-start-3 flex justify-between col-span-2">
        <button v-if="shownewperson == false" type="button"
        className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-teal-600 p-1 mt-auto mb-auto" @click="newPerson()">Neue Person
        anlegen</button>
        <button v-else-if="shownewperson == true"
                className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-teal-600 p-1 mt-auto mb-auto"
                @click="cancelnewperson()">Abbruch</button>
      <button  type="button" v-if="shownewperson == false"
        className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-teal-600 p-1 mt-auto mb-auto w-fit" @click="addPerson()">Hinzufügen</button>

      <button  type="button" v-else-if="shownewperson == true"
               className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-teal-600 p-1 mt-auto mb-auto w-fit" @click="saveNewPerson">Neu Anlegen</button>
      </span>

      
    </div>
  </div>
  <div v-on:click="closePopup()" id="black-bg"
    className="bg-black top-0 left-0 w-full h-full z-10 opacity-80 overflow-visible fixed">
  </div>
</template>

<script>

import {personListStore} from "@/stores/personList";
import {personStore} from "@/stores/person";

import {mapState} from "pinia";
export default {

  name: "AddPersonTemplate",
  props: ['activeDelete', 'activeRelation', 'activePerson', 'activeSaved', 'activeBegine', 'regestenData', 'person', 'regest'],
  emits: ['close-popup', 'add-person'],
  data() {
    return {
      personsearchstring: null,
      personid: null,
      shownewperson: false
    }
  },

  computed: {
    personListStore: function (){
      return personListStore();
    },
    ...mapState(personListStore,[
      'personlist',
      'personsearchresult',
      'persondata'
    ]),

    personStore: function (){
      return personStore();
    },
    ...mapState(personStore,[
        'temp_data',
        'tempFromDate',
        'tempToDate'
    ]),

  },

  watch: {
    personid: function(newid) {
      this.personStore.getTempData(newid)
    }
  },

  methods: {

    addPerson: function () {
      this.personListStore.resetSearchResult();
      this.$emit("add-person", this.personStore.temp_data);
    },

    selectPerson: function (e) {
      this.personsearchstring = e.target.getAttribute('data-name');
      this.personid = e.target.getAttribute('data-id');
    },

    newPerson: function (){
      this.personid = null;
      this.personStore.setTempData({
            forname: "",
            surname: "",
            label: "",
            note: ""
          });
      this.shownewperson = true;
    },

    saveNewPerson: async function (){
      let newPerson = await this.personStore.saveNewTempData();
      console.log(newPerson);
          this.addPerson();
    },

    closePopup: function() {
      this.personListStore.resetSearchResult();
      this.personStore.resetTempData();
      this.$emit("close-popup");
    },

    cancelnewperson: function (){
      this.personStore.resetTempData();
      this.shownewperson = false;
    },



  }

}
</script>

<style scoped></style>