import { defineStore } from 'pinia'
import {httpurl} from "@/main";
import axios from "axios";

import { personStore } from "./person";

export const personListStore = defineStore('personList',{
    state: function (){
        return{
            personlist: null,
            personsearchresult: ""
        }
    },

    actions:{
        async loadPersonList() {
            const response = await axios.get(httpurl + '/persons', {
                headers: {
                    "Content-Type": "application/json",
                }
            }).catch(error => {console.log(error)});
          this.personlist = await response.data
        },

        getTempPersonData(id){
            const personstore = personStore();
            personstore.getTempData(id);
        },

        getPersonNameOverId(id){
            if(this.personlist.length > 0) {
                var person = this.personlist.filter(obj => {
                    return obj.id.includes(id)
                })
                return person[0].name;
            }
        },

        getPersonOverId(id){
            if(this.personlist.length > 0) {
                var person = this.personlist.filter(obj => {
                    return obj.id.includes(id)
                })
                console.log(person[0])
                return person[0];
            }
        },

        searchPersonList(searchstring) {
            const isWhitespaceString = !/\S/.test(searchstring)
            if (searchstring.length >= 1 && !isWhitespaceString) {
                console.log(searchstring)
                let isnum = /^\d+$/.test(searchstring);
                if(isnum  ) {this.personsearchresult = this.personlist.filter(obj => {return obj.entrynr.includes(searchstring)});}
                else if(searchstring.includes('per:')) {
                    console.log("Suche in idno")
                    this.personsearchresult =  this.personlist.filter(obj => {return obj.idno.includes(searchstring)});}
                else{this.personsearchresult = this.personlist.filter(obj => {return obj.name.includes(searchstring)});}
            }
            else { this.personsearchresult = {} }

        },

        resetPersonData(){ this.persondata = null },

        resetSearchResult(){  this.personsearchresult = null },

        addPersonToList(data){
            this.personlist.push(data) }

    },
    getters:{

    }
})