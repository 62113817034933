<template>
  <EditorHeader></EditorHeader>
    <span>
      <input @input="entriesStore.searchEntries(searchstring)" type="text" v-model="searchstring" placeholder="Suchen..." className="border-2 border-teal-600 rounded focus:outline-none focus:shadow-lg
               focus:shadow-gray-200 ml-10 mt-10 w-80 h-10 p-4" />

    <button class="border-2 border-teal-600 rounded p-1.5 ml-2" @click="toggleBeginePopup()">Neue Begine
      hinzufügen</button>

  </span>


  <div v-if="entries" className="grid grid-flow-row grid-cols-4 w-full p-10 gap-5">
    <router-link v-for="entry in searchresult" :key="entry" v-bind:to="'editor/entry/' + entry.id"
      className="group border-2 border-black hover:bg-gray-200 flex flex-col justify-between">
      <h2>{{ entry.nr }}</h2>
      <p className="font-bold p-2">Name: {{ entry.name.forename }} {{ entry.name.surname }}</p>
      <div :class="colorbarclass(entry.state)"><br></div>
    </router-link>
  </div>

  <AddBegineTemplate @close-popup="toggleBeginePopup()" v-if="showPopup"></AddBegineTemplate>
  <LoginTemplate v-if="showlogin" @login="loggedin"></LoginTemplate>
</template>


<script>
import EditorHeader from './EditorHeader.vue';
import AddBegineTemplate from './popuptemplates/AddBegineTemplate.vue';
import LoginTemplate from "./popuptemplates/LoginTemplate.vue";
import { entriesStore } from "@/stores/entries";
import { mapState } from "pinia";
import {personStore} from "@/stores/person";

export default {
  name: "EntriesOverview.vue",
  components: {LoginTemplate, EditorHeader, AddBegineTemplate }, //JwPagination, PaginationTemp
  data() {
    return {
      searchstring: "",
      showPopup: false,
      activeBegine: false,
      showlogin: false,
    }
  },
  computed:{
    entriesStore: function (){
      return entriesStore();
    },
    ...mapState(entriesStore,[
        'entries',
        'searchresult'
    ]),

    personStore: function (){
      return personStore();
    },
    ...mapState(personStore, [

    ])

  },

  created() {
    this.personStore.resetActiveData();

    if(sessionStorage.getItem('userName') === null){this.showlogin = true}
    else {
      this.entriesStore.loadEntries()
    }
  },

  methods: {

    toggleRelationPopup() {
      this.showPopup = !this.showPopup;
      this.activeRelation = !this.activeRelation;
    },
    toggleDeletePopup() {
      this.showPopup = !this.showPopup;
      this.activeDelete = !this.activeDelete;
    },
    toggleBeginePopup() {
      this.showPopup = !this.showPopup;
      this.activeBegine = !this.activeBegine;
    },
    togglePersonPopup() {
      this.showPopup = !this.showPopup;
      this.activePerson = !this.activePerson;
    },
    toggleSavedPopup() {
      this.showPopup = !this.showPopup;
      this.activeSaved = !this.activeSaved;
    },

    colorbarclass(state){
      let classstring = 'w-full '
      if(state === 'original') {classstring += 'bg-red-500'}
      else if (state === 'inprogress'){classstring += 'bg-yellow-500'}
      else if(state === 'done'){classstring += 'bg-green-500'}
      else if(state === 'new'){classstring += 'bg-blue-800'}
      return classstring

    },

    loggedin(){
      this.entriesStore.loadEntries()
      this.showlogin = false
    }
  }
}
</script>
<style scoped></style>