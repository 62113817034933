<template>
  <router-view :key="$route.path"/>


</template>

<script>
import './css/App.css'
import  './css/index.css'

export default {
  name: 'App',
  components: {
},
  data(){
    return{
      loaded: false,
      beginenjson :{}
    }
  },

  mounted(){


  }



}
</script>

<style>
</style>
