<template>
  <EditorHeader />
  <div className='flex flex-row pt-5 pb-5 h-[85vh] overflow-hidden'>
    <div className="flex flex-col w-1/2 h-full overflow-scroll">
      <div className='flex justify-between pr-8 pl-16'>
        <span v-if="personStore.active_data" className="flex flex-row">
          <button v-if="personStore.active_data.lastbegineid" @click="goTo(personStore.active_data.lastbegineid)" type="button" className="hover:bg-gray-200 mr-4 border-2 rounded-md border-teal-600 p-1">Vorherige
            Begine</button>
          <button v-if="personStore.active_data.nextbegineid"  @click="goTo(personStore.active_data.nextbegineid)"  className="hover:bg-gray-200 mr-4 border-2 rounded-md border-teal-600 p-1">Nächste
            Begine</button>
          <button v-if="personStore.active_data.sameas.name" @click="goTo(personStore.active_data.sameas.id)"  className="hover:bg-gray-200 mr-4 border-2 rounded-md border-teal-600 p-1">
            Identisch mit: {{personStore.active_data.sameas.name}}
          </button>
        </span>
        <label v-if="personData" class="grid grid-cols-checkbox gap-3.5 leading-snug w-fit h-fit text-lg mb-auto mt-auto">
          <input type="checkbox" :checked="personData.state === 'done' ? true : false" v-on:change="checkeboxtoggle()" class="appearance-none bg-white font-[strawford] mb-auto
                       text-black w-5 h-5 border-2 border-black rounded -translate-y-0.075 grid place-content-center before:w-4 before:h-4 before:scale-0
                       before:ease-in-out before:duration-150 before:shadow-check checked:before:scale-50 hover:shadow-check-hover mt-auto" >
        <!--  <input type="checkbox" :checked="personData.state === 'done' ? true : false" class="appearance-none bg-white font-[strawford] mb-auto
                       text-black w-5 h-5 border-2 border-black rounded -translate-y-0.075 grid place-content-center before:w-4 before:h-4 before:scale-0
                       before:ease-in-out before:duration-150 before:shadow-check checked:before:scale-50 hover:shadow-check-hover mt-auto" > -->
          Fertig!
        </label>
        <span className="flex flex-row">
          <button type="button" className='hover:bg-gray-200 mr-4 border-2 rounded-md border-teal-600 p-1'
            @click="changePreview('editorPreview')">Vorschau</button>
          <button type="button" className='hover:bg-gray-200 mr-4 border-2 rounded-md border-teal-600 p-1'
            @click="changePreview('askSam')">AskSam</button>
        </span>

      </div>
      <div v-if="this.previewMode == 'askSam'" id='askSam' className="w-full">
        <AskSamView v-if="this.asksamData" @changeeditor="changeEditor" :asksamData="asksamData" />
      </div>
      <div v-if="this.previewMode == 'editorPreview' && regestenStore.regestenList && personListStore.personlist && personStore.active_data"  id='begPrev' className="w-full">
        <EditorPreview @changeeditor="changeEditor" />
      </div>
    </div>

    <div v-if="personListStore.personlist" className="w-1/2 flex flex-row h-full overflow-scroll">
      <!-- Hier später die Components für Personen und Regesten einfügen -->
      <div v-if="this.editorMode == 'personenEdit' && this.personStore.active_data && personListStore.personlist" id="personen" className="w-full pr-10">
      <PersonEditor/>

      </div>
      <div v-if="this.editorMode == 'regestenEdit' && regestenStore.regestenList && personListStore.personlist" id="regesten" className="w-full pr-10">
        <RegestenEditor :conventsList="this.konventeList" />
      </div>
    </div>
    <SaveTemplate v-if="onsave" :savemode="'all'" @savedone="this.onsave = false" @saveerror="abortsave"  @close-popup="this.onsave = false"></SaveTemplate>
    <LoginTemplate v-if="showlogin" @login="login" />

  </div>
</template>


<script>
import { mapState } from "pinia";

import EditorHeader from './EditorHeader.vue';
import PersonEditor from './editors/PersonEditor.vue';
import RegestenEditor from './editors/RegestenEditor.vue';
import EditorPreview from "@/components/previews/EditorPreview.vue";
import AskSamView from "@/components/previews/AskSamView.vue";
import SaveTemplate from "./saves/SaveTemplate.vue";
import LoginTemplate from "@/components/popuptemplates/LoginTemplate.vue";


import {personListStore} from "@/stores/personList";
import {regestenStore} from "@/stores/regesten";
import {personStore} from "@/stores/person";
import {topoListStore} from "@/stores/topoList";

export default {
  name: "EditorContent.vue",
  components: {LoginTemplate, EditorHeader, EditorPreview, AskSamView, PersonEditor, RegestenEditor, SaveTemplate, },
  data() {
    return {
      previewMode: 'editorPreview',
      editorMode: 'personenEdit',

      konventeList: null,

      personData: null,
      regestenData: null,
      asksamData: null,

      onsave: false,
      awaitechange: false,
      gotoid: '',
      showlogin: null,
    }
  },


  watch:{
    onsave(){
      console.log("Onsave geändert")
      if(this.onsave == false && this.awaitechange == true){
        this.$router.push({ path: this.gotoid})
      }
    }
  },

  computed: {
    personListStore: function (){
      return personListStore();
    },
    ...mapState(personListStore,[
        'personlist'

    ]),

    personStore: function (){
      return personStore();
    },
    ...mapState(personStore,[
        'active_id',
        'active_data'
    ]),

    regestenStore: function(){
      return regestenStore();
    },
    ...mapState(regestenStore, [
        'regestenList',
        'active_id',
        'active_data'
    ]),

    topoListStore: function (){
      return topoListStore();
    },
    ...mapState(topoListStore, [
       'topolist',
    ]),


    asksamnr() {
      if (this.personData.asksam.includes('_')) {
        return this.personData.asksam.split('_')[1]
      }
      else { return this.personData.asksam }

    },
    typ() { return this.$route.params.typ },
    personuuid(){return this.$route.params.id}
  },

  async created() {
    this.personStore.resetActiveData();

    this.personStore.setActiveId(this.$route.params.id);

    if(sessionStorage.getItem('userName') === null){this.showlogin = true}
    else {this.loaddata()}
  },

  methods: {
   async loaddata(){


      await this.getPersonData();
      await this.personStore.setActiveData();
      await this.regestenStore.loadRegestenList();
      await this.personListStore.loadPersonList();

      await this.getAskSamEntry();
      await this.topoListStore.loadTopoList();
      await this.getConventsList()
    },

    changePreview: function (previewMode) {
      this.previewMode = previewMode
    },
    changeEditor: function (editormode, id) {
     if(editormode === 'regestenEdit'){ this.regestenStore.setActiveDataOverID(id); }
     else{
       this.regestenStore.resetActiveData();
     }
      this.editorMode = editormode;
    },

    relations: function () {
      var par = document.getElementById("relations");
      const clonedChild = par.childNodes[1].cloneNode(true);
      clonedChild.className = "grid grid-cols-2 grid-flow-col gap-2 col-span-4 col-start-2"
      par.appendChild(clonedChild);
    },


    async getPersonData() {
      const url = this.httpurl + '/person/id/' + this.personuuid
      const getPersonresponse = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
      this.personData = await getPersonresponse.json()
      sessionStorage.setItem('persondata', JSON.stringify(this.personData))
    },
    async getRegestenData() {
      const response = await fetch(this.httpurl + '/regestswithperson/' + this.personuuid, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
      this.regestenData = await response.json()
    },
    async getAskSamEntry() {
      const responsAskSam = await fetch(this.httpurl + '/entry/' + this.asksamnr, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
      this.asksamData = await responsAskSam.json()
    },

    async getConventsList(){
     const response = await fetch(this.httpurl + '/convents', {
       method: "GET",
           headers:{
         "Content-Type": "application/json",
        }
      })
      this.konventeList = await response.json()
    },

    checkeboxtoggle(){
      this.personData.state = event.target.checked ? 'done' : 'inprogress'
    },

    goTo: function (beginenid){
      this.gotoid = beginenid
      this.awaitechange = true
      this.onsave = true
    },

    login(){
     this.showlogin = false;
     this.loaddata();
    },

    abortsave(){
     this.awaitechange = false
      this.onsave = false
    }

  },






}
</script>


<style scoped></style>