import { createRouter, createWebHistory } from 'vue-router'
import EntriesOverview from '../components/EntriesOverview.vue'
import EditorContent from '../components/EditorContent.vue'
import PersonEditor from '../components/editors/PersonEditor.vue'
import RegestenEditor from '../components/editors/RegestenEditor.vue'

const routes = [


  {
    path: '/',
    name: 'Overview',
    component: EntriesOverview
  },

  {
    path: '/editor',
    name: 'Editor',
    component: EditorContent
  },
  {
    path: '/editor/:typ/:id',
    name: 'Editor_type',
    component: EditorContent
  },
  {
    path: '/person',
    name: 'Personen',
    component: PersonEditor
  },
  {
    path: '/regest',
    name: 'Regesten',
    component: RegestenEditor
  },
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
