<template>
  <div v-if="topoListStore.topolist" id="addtopopopup"
    className="fixed top-[25%] left-[25%] lg:left-[35%] z-20 bg-gray-100 border-teal-600 rounded border-2 w-1/2 lg:w-1/3 h-fit pl-4 pt-4">
    <h3 className='mt-auto mb-auto font-semibold'>Neue Topographie</h3>


    <div v-if="shownewtopo === false" className=" grid grid-cols-2 grid-flow-row gap-4">
      <label className='mt-auto mb-auto font-semibold'>Suche: </label>
      <input className='mr-4 border-gray border-2 rounded-md border-gray p-1 mt-2 mb-auto '
             @input="topoListStore.searchTopoList(searchstring)"
             v-model="searchstring" />
    </div>
    <span class="flex flex-row">
      <div v-if="topoListStore.searchresult && shownewtopo === false" className="overflow-scroll max-h-60 w-1/2 my-2">
        <ul>
          <li className="hover:bg-gray-200"
              v-for="topo in topoListStore.searchresult" :key="topo"
              @click="selectTopo($event.target.innerText)">
            {{ topo.keussen }}
          </li>
        </ul>
    </div>

    <div v-if="selectedtopo" class="flex flex-col my-2 w-1/2">
      <h3>Topographie</h3>
      <label className='mt-auto mb-auto font-semibold'>Keussen: </label>
      <input className='mr-4 border-gray border-2 rounded-md border-gray p-1 mt-2 mb-auto' v-model="selectedtopo.keussen" />
      <label className='mt-auto mb-auto font-semibold'>Mapid: </label>
      <input className='mr-4 border-gray border-2 rounded-md border-gray p-1 mt-2 mb-auto' v-model="selectedtopo.mapid" />
      <label className='mt-auto mb-auto font-semibold'>Anmerkungen: </label>
      <input className='mr-4 border-gray border-2 rounded-md border-gray p-1 mt-2 mb-auto' v-model="selectedtopo.notes" />
    </div>
    </span>
    
    <span class="row-start-3 flex justify-between col-span-2">
      <button className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-teal-600 p-1 mb-6"
      @click="newTopo()">Neue Topo</button>
       <button  type="button" v-if="shownewtopo === false && selectedtopo !== null"
                className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-teal-600 p-1 mt-auto mb-auto w-fit" @click="addTopo()">Hinzufügen</button>
    <button  type="button" v-if="shownewtopo === true && selectedtopo.keussen !== null"
           className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-teal-600 p-1 mt-auto mb-auto w-fit" @click="createNewTopo()">Anlegen</button>
    </span>
    
  </div>
  <div v-on:click="closePopup()" id="black-bg"
    className="bg-black top-0 left-0 w-full h-full z-10 opacity-80 overflow-visible fixed">
  </div>

</template>

<script>

import {mapState} from "pinia";

import {topoListStore} from "@/stores/topoList";
export default {

  name: "AddTopoTemplate",
  emits: ['close-popup', 'add-topo'],
  data() {
    return {
      searchstring: null,
      toposearchresult: {},
      selectedtopo: null,
      shownewtopo: false
    }
  },
  computed:{
    topoListStore: function (){
      return topoListStore();
    },
    ...mapState(topoListStore, [
      'topolist',
    ]),
  },

  methods: {

    selectTopo: function (keussen) {
      this.selectedtopo = this.topoListStore.getTopoWithKeussen(keussen);
      this.searchstring = this.selectedtopo.keussen;
    },

    addTopo: async function (){
      await this.topoListStore.updateTopo(this.selectedtopo);
      this.$emit("add-topo", this.selectedtopo);
      },

    newTopo: function (){
      this.shownewtopo = true;
      this.selectedtopo = { 'keussen': null, 'mapid': null, 'id': null };
    },

    closePopup: function () {
      this.$emit("close-popup");
    },

    createNewTopo: async function (){
      this.topoListStore.saveNewTopo(this.selectedtopo).then((response) => {
        let newtopo = response.data;
        this.topoListStore.addTopoToList(newtopo);
        this.$emit('add-topo', newtopo);
      });
    },



    /*
    autocompleteTopo() {
      this.toposearchresult['keussen'] = []
      this.toposearchresult['mapid'] = []
      this.toposearchresult['keussen'] = this.placeList.filter(obj => { return obj.keussen.includes(this.searchstring) })
      this.toposearchresult['mapid'] = this.placeList.filter(obj => { return obj.mapid.includes(this.searchstring) })

    },

    selecttopo(index, mode) {
      this.oldtopo = JSON.parse(JSON.stringify(this.toposearchresult[mode][index]))
      this.selectedtopo = this.toposearchresult[mode][index]
    },
*/
    /*
    addtopo() {
      if (this.selectedtopo.id != '') {
        if (!_.isEqual(this.selectedtopo, this.oldtopo)) {
          const data = this.selectedtopo
          axios.request({
            method: 'post',
            url: this.httpurl + '/update/place',
            headers: {
              "pass" : sessionStorage.getItem('pass'),
              "name" : sessionStorage.getItem('userName')
            },
            data: data,
          }).then((response) => {
            console.log(response.data)
            this.$emit("addTopo", response.data)
          })
        }
        else { this.$emit("addTopo", this.selectedtopo) }
      }
      else {
        if (this.selectedtopo.mapid != '' || this.selectedtopo.keussen != ''){
        const data = this.selectedtopo
        axios.request({
          method: 'post',
          url: this.httpurl + '/new/place',
          headers: {
            "pass" : sessionStorage.getItem('pass'),
            "name" : sessionStorage.getItem('userName')
          },
          data: data,
        }).then((response) => {
          this.$emit("addTopo", response.data)
        })
          }
      }

    },

     */



  }
}
</script>

<style scoped></style>