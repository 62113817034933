<template>
    <div id="adddeletepopup"
        className="fixed top-[25%] left-[35%] z-20 bg-gray-100 border-red-500 rounded border-4 w-1/3 h-1/3 pl-4 pt-4">
        <h3 className='mt-auto mb-auto font-semibold'>Person Löschen:</h3>
        <div className="flex flex-col justify-between h-5/6">
            <div className=" mt-8">
                <p className="text-lg">Sind Sie sicher, dass  <span className="font-bold" v-if="person">
                        {{ personData.name.forname }} {{ personData.name.surnames[0] }}</span>
                    <span className="font-bold" v-if="regest">
                        {{ regestAsksam }}</span>
                    gelöscht werden soll?
                </p>
            </div>
            <form>
                <input type="checkbox" name="delete" id="delCheck" value="delete" @click="enableDelButton()" />
                <label className="ml-4" for="delete">Ja, ich möchte
                    <span className="font-bold" v-if="person">{{ personData.name.forname }} {{ personData.name.surnames[0]
                    }}</span>
                    <span className="font-bold" v-if="regest">{{ regestAsksam }}</span>
                    löschen!</label>
            </form>
            <div className="flex flex-row justify-between ">
                <button type="button" v-on:click="closePopup()"
                    className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-teal-600 p-1 mt-auto mb-auto ">Abbrechen</button>
                <button @click="deleteregest" type="button" disabled id="delButton"
                    className="hover:bg-red-200 outline-none mr-4 border-2 rounded-md border-red-500 p-1 mt-auto mb-auto disabled:border-gray-500 disabled:bg-gray-200 disabled:opacity-30">Ganz Löschen</button>
              <button v-if="regest" type="button" disabled id="delButton"
                      className="hover:bg-red-200 outline-none mr-4 border-2 rounded-md border-red-500 p-1 mt-auto mb-auto disabled:border-gray-500 disabled:bg-gray-200 disabled:opacity-30">aus Begine Löschen</button>

              <!--@click="deletePerson()"-->
            </div>
        </div>
    </div>
    <div v-on:click="closePopup()" id="black-bg"
        className="bg-black top-0 left-0 w-full h-full z-10 opacity-80 overflow-visible fixed">
    </div>
</template>
<script>

export default {
    name: "DeleteTemplate",
    props: ['regestId', 'regestAsksam', 'personId','personName', 'person', 'regest'],
    data() {

    },

  methods: {
        closePopup() {
            this.$emit("close-popup");
        },
        enableDelButton() {
            var delCheck = document.getElementById("delCheck");
            var delButton = document.getElementById("delButton");

            if (delCheck.checked) {
                delButton.disabled = false;
            }
            else {
                delButton.disabled = true;
            }
        },

      deleteregest(){
          this.regestenStore.loadDeleteRegestOverId(this.regestId);
      }
    }
}
</script>
<style scoped></style>