<template>
  <div className='grid gap-2 pl-16 pt-8 pb-8 '>

    <!-- Preview Person -->
    <div :class="colorpeopleclass(personStore.active_data.type)" @click="togglePerson">
      <h3 className='pr-2 float-left font-semibold text-white'>Person:</h3>
    </div>
    <PersonPreview @changeeditor="changeEditor"/>

    <div className="bg-blue-800 hover:bg-blue-900 mr-10 rounded p-4 flex flex-row justify-between"
      @click="toggleRegesten">
      <h3 className='pr-2 float-left font-semibold text-white mb-auto mt-auto'>Regesten:</h3>
      <span>
        <button type="button" class="border-2 border-blue-400 rounded p-1.5 ml-2 bg-white" @click="sortRegesten">Chronologisch
          sortieren</button>
        <button type="button" class="border-2 border-blue-400 rounded p-1.5 ml-2 bg-white" @click="addRegest">Neues Regest
          hinzufügen</button>
      </span>
    </div>

    <div id="regestenDiv" className="hidden flex-col">
      <div v-for="(regest, index) in regestenStore.regestenList" :key="regest"
           className="hover:bg-gray-200 mr-10 rounded-md p-4 mb-2 border-2 border-blue-800 "
           role="button"
           @click="changeEditor('regestenEdit', regest.id)" >
        <RegestPreview :regest="regest" :index="index+1"/>
      </div>
    </div>
  </div>
</template>

<script>
// Third Party
import {mapState} from "pinia";

// Components
import RegestPreview from "./RegestPreview.vue";
import PersonPreview from "./PersonPreview.vue";

//Stores
import {regestenStore} from "@/stores/regesten";
import {personStore} from "@/stores/person";

export default {
  name: "EditorPreview",
  components:{PersonPreview, RegestPreview},
  data() {
    return {
      loaded: false,
      regesten: this.regestenData,
      showregesten: true
    }
  },
  emits: ['changeeditor'],
  computed:{
    regestenStore: function(){ return regestenStore(); },
    ...mapState(regestenStore, [
        'regestenList',
        'active_data'
    ]),

    personStore: function (){ return personStore();},
    ...mapState(personStore, [
        'active_data'
    ]),

  },

  methods: {
    /*******************************************************
     Frontend displaying functions
     *******************************************************/
    colorpeopleclass(type) {
      let classstring = 'rounded mr-10 p-4 '
      if (type === 'begine') { classstring += 'bg-teal-600 hover:bg-teal-700' }
      else { classstring += 'bg-cyan-500 hover:bg-cyan-600' }
      return classstring

    },

    togglePerson: function () {
      const persDiv = document.getElementById("ASPersonDiv");
      if (persDiv.style.display == "flex") { persDiv.style.display = "none"; }
      else { persDiv.style.display = "flex"; }
    },

    toggleRegesten: function () {
      const regDiv = document.getElementById("regestenDiv");
      if (regDiv.style.display == "flex") { regDiv.style.display = "none"; }
      else { regDiv.style.display = "flex"; }
    },

    changeEditor: function (mode, index) { this.$emit('changeeditor', mode, index); },


    /*******************************************************
     Add or Manipulate Data
     *******************************************************/
    sortRegesten: function(e){
      e.stopPropagation();
      this.regestenStore.sortRegestenListOverData();
    },

    addRegest: async function (e){
      e.stopPropagation();
      this.regestenStore.addNewRegest();

    },
  },
}
</script>

<style scoped></style>