<template>
  <div className="grid fixed top-[25%] left-[35%] z-20 bg-gray-100 border-red-500 rounded border-4 w-1/3 h-1/3 pl-4 pt-4">
    <h3 className='mt-auto mb-auto font-semibold row-start-1'>Änderungen in:</h3>
    <div v-if="unequalregesten.length > 0" class="overflow-scroll" >
        <SaveRegest v-for="(regest, index) in unequalregesten" :key="regest"
                    :save="saveall"
                    :regestid="regest"
                    @done="removeFromUnequalRegsten(index)"/>
    </div>
    <div v-if="personStore.dataIsEqual(personStore.active_data, personStore.old_data) !== true && this.saveperson === true" class="overflow-scroll" >
      <SavePerson :personisequal="personStore.dataIsEqual(personStore.active_data, personStore.old_data)" :save="saveall" @done="persondone = true" />
    </div>

    <button @click="saveAll"
            className="hover:bg-red-200 outline-none mr-4 border-2 rounded-md border-red-600 p-1 mt-auto mb-auto w-20">Alles Speichern</button>

  </div>

  <div v-on:click="closePopup()" id="black-bg"
    className="bg-black top-0 left-0 w-full h-full z-10 opacity-80 overflow-visible fixed">
  </div>
</template>

<script>

import {mapState} from "pinia";

import SaveRegest from "./SaveRegest.vue"
import SavePerson from "./SavePerson.vue";

//import LoginTemplate from "@/components/popuptemplates/LoginTemplate.vue";

import {regestenStore} from "@/stores/regesten";
import {personStore} from "@/stores/person";

export default {
  name: "SaveTemplate.vue",
  emits: ['savedone', 'close-popup', 'saveerror'],
  components: {SaveRegest, SavePerson},
  props: [ 'savemode' ],
  data() {
    return {
      unequalregesten: [],
      saveall: false,
      saveperson: false,
      regestendone: false,
      persondone: false
    }
  },
  computed:{
    regestenStore: function(){ return regestenStore(); },
    ...mapState(regestenStore, [
      'old_regestenList',
      'active_id',
      'active_data',
      'getUnequealRegestenList'
    ]),

    personStore: function (){ return personStore();},
    ...mapState(personStore, [
        'active_data',
        'old_data'
    ]),
  },

  created() {
    console.log(this.savemode)
    if(this.savemode === 'all') {
      if (this.personStore.dataIsEqual(this.personStore.active_data, this.personStore.old_data) != true ||  this.regestenStore.getUnequealRegestenList.length > 0) {
        this.unequalregesten = this.regestenStore.getUnequealRegestenList;
        this.saveperson = true;
      }
      else{
        this.regestendone = true;
        this.persondone = true;}
    }
    else if(this.savemode === 'regest'){
      this.unequalregesten = this.regestenStore.getUnequealRegestenList;
      this.persondone = true;
    }
    else if(this.savemode === 'person'){
      this.saveperson = true;
      this.regestendone = true; }
  },

  watch: {
    regestendone(newvalue){
      if(newvalue === true && this.persondone === true){ this.closePopup(); }
    },

    persondone(newvalue){
      if(newvalue === true && this.regestendone === true){ this.closePopup(); }
    },

    unequalregesten(){
      if(this.unequalregesten.length === 0){ this.regestendone = true; }
    }
  },

  methods: {
    removeFromUnequalRegsten: function (index){
      this.unequalregesten.splice(index, 1);
      if(this.unequalregesten.length === 0){ this.regestendone = true; }
    },

    saveDone: function(){ this.$emit('savedone'); },

    closePopup: function () { this.$emit("close-popup"); },

    saveAll: function () {
      console.log("saveall");
      this.saveall = true; },
  }
}
</script>



<style scoped></style>