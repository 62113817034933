<template>
  <div id="loginpopup"
    className="fixed top-[15%] left-[35%] z-20 bg-gray-100 border-teal-600 rounded border-2 w-1/3 h-fit p-4 overflow-scroll">
    <h2 class="">Login</h2>
    <h3 v-if="loginmsg">{{loginmsg}}</h3>
    <div class="flex justify-center pt-6">
      <form @submit.prevent="submit" method='post' class="">
        <input name='{ $key }' value='{ $value }' type='hidden' />
        <table>
          <tr>
            <!-- <td class="border-none"><b>Benutzername:</b></td> -->
            <td class="border-none">
              <input type="text" v-model="name" id='user' size='30'
                className='border-2 rounded-md border-gray p-1 mt-auto mb-auto' placeholder="Benutzername"/>
            </td>
          </tr>
          <tr>
            <!-- <td class="border-none"><b>Password:</b></td> -->
            <td class="border-none">
              <input name='pass' type='password' v-model="pass" size='30'
                className='border-2 rounded-md border-gray p-1 mt-auto mb-auto' placeholder="Password"/>
            </td>
          </tr>
          <tr>
            <!-- <td class="border-none"></td> -->
            <td class="border-none">
              <button type='submit'
                class="hover:bg-teal-700 bg-teal-600 text-white outline-none border-2 rounded-md p-1 mt-auto mb-auto w-full border-teal-600">Login</button>
            </td>
          </tr>
        </table>
      </form>
    </div>
    <div v-if="error">Login fehlgeschlagen!
    </div>
  </div>
  <div id="black-bg"
    className="bg-black top-0 left-0 w-full h-full z-10 opacity-80 overflow-visible fixed">
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LoginTemplate",
  props: { loginmsg: null },
  data() {
    return {
      pass: '',
      name: '',
      error: false
    }
  },
  emits: ['login'],

  methods: {
    submit() {
      axios({
        method: 'POST',
        withCredentials: true,
        url: this.httpurl + '/login',
        data:{
          "name": this.name,
          "pass": this.pass
        }
      }).then((res) => {
        let data = res.data
        if ('err' in data) { this.error = true }
        else {
          sessionStorage.setItem("sessionId", data.apiKey);
          sessionStorage.setItem('userGroups', data.user.groups)
          sessionStorage.setItem('pass', this.pass)
          sessionStorage.setItem('userName', data.user.name)
          this.$emit('login')
        }
      })
    },
    checksession() {
      axios({
        method: 'GET',
        withCredentials: true,
        url: this.httpurl + '/testuser'
      }).then((res) => {
        console.log(res)
      })
    }
  }

}
</script>
<style scoped></style>