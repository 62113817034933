<template>
  <div id="addconventpopup"
       className="fixed top-[15%] left-[35%] z-20 bg-gray-100 border-teal-600 rounded border-2 w-1/3 h-3/4 lg:h-fit p-4 overflow-scroll">
    <h3 className='mt-auto mb-auto font-semibold'>Konvent</h3>
    <div className=" gap-4 mt-4">
      <input name="conventname" type="text" className='mr-4 border-2 rounded-md border-gray-300 p-1 mt-auto mb-auto'
             @input="autocompleteconvent" v-model="searchstring" />
      <div id="autocompleteresult" className="max-h-24 overflow-scroll col-start-2">
        <ul>
          <li @click="selectconvent" className="hover:bg-gray-200" v-for="(convent, index) in searchresult"
              :key="convent" v-bind:id="'result_' + index"
              v-bind:data-id="convent.id" v-bind:data-name="convent.name">{{ convent.name }}</li>
        </ul>
      </div>
    </div>
  </div>

  <div v-on:click="closePopup()" id="black-bg"
       className="bg-black top-0 left-0 w-full h-full z-10 opacity-80 overflow-visible fixed">
  </div>
</template>

<script>

export default {

  name: "AddConventsTemplate",
  props: ['conventsList'],
  emits: ['close-popup', 'add-convent'],
  data() {
    return {
      searchstring: null,
      searchresult: null,
      selectedconvent: {}
    }
  },
    methods: {
      closePopup() {
        this.$emit("close-popup");
      },

      addConvent() {
        this.$emit("add-convent", {'name': this.searchstring, 'id': this.conventid})
      },

      autocompleteconvent() {
        if (this.searchstring.length >= 1) {
            this.searchresult = this.conventsList.filter(obj => {let string = obj.nr + ' ' + obj.name; return string.includes(this.searchstring)})
          }
        else {
          this.searchresult = this.entries;
        }
        },

      selectconvent(e){
          this.searchstring = e.target.getAttribute('data-name')
          this.conventid = e.target.getAttribute('data-id')
        this.addConvent()
      },



    }
  }
</script>

<style scoped></style>