import { defineStore } from 'pinia'
import {httpurl} from "@/main";
import axios from "axios";
import _ from 'lodash';



export const topoListStore = defineStore('topoList',{
    state: function (){
        return{
            topolist: null,
            searchresult: [],
        }
    },

    actions:{
        loadTopoList: async function() {
            const response = await axios.get(httpurl + '/places', {
                headers: {
                    "Content-Type": "application/json",
                }
            }).catch(error => {console.log(error)});
            this.topolist = await response.data
        },

        searchTopoList: function(searchstring) {
            const isWhitespaceString = !/\S/.test(searchstring)
            if (searchstring.length >= 1 && !isWhitespaceString) {
                this.searchresult = this.topolist.filter(obj => { return obj.keussen.includes(searchstring) });
            }
            else { this.searchresult = {} }
        },

        resetTopoList: function(){ this.topolist = null },

        resetSearchResult: function(){  this.searchresult = null },

        addTopoToList: function(data){ this.topolist.push(data) },


        updateTopo: async function(topo){
            let oldtopo = this.getTopoWithId(topo.id);
            if (!_.isEqual(topo, oldtopo)) {
                const data = topo;
               let response = axios.post(httpurl + '/update/place', data, {
                    headers: {
                        "pass" : sessionStorage.getItem('pass'),
                        "name" : sessionStorage.getItem('userName')
                    }
                });
               return response.data;
            }

        },

        saveNewTopo: async function(topo) {
            if (topo.mapid !== '' || topo.keussen !== ''){
                const data = topo;
                return axios.post(httpurl + '/new/place', data, {
                    headers: {
                        "pass" : sessionStorage.getItem('pass'),
                        "name" : sessionStorage.getItem('userName')
                    }
                });
            }
        },

        getTopoAtIndex: function(index) { return this.topolist[index]; },

        getTopoWithKeussen: function(keussen){ return this.topolist.filter(obj => { return obj.keussen === keussen; })[0]; },

        getTopoWithId: function(id) {return this.topolist.filter(obj => {
            return obj.id === id; })[0];}

       // selectTopo: function(id) {this.searchresult  },

    },
    getters:{

    }
})