<template>
    <header className='border-b-2 border-teal-600 flex flex-row justify-between sticky top-0 bg-white'>
            <h2 className="pl-10">Beginen-Editor</h2>
            <span className="mt-auto mb-auto h-fit  text-center">
                <!-- <router-link to="/editor" className="hover:text-amber-600 h-fit">Editor</router-link> -->
                <!-- <router-link to="/regest" className="hover:text-red-500 h-fit">Regesten</router-link>
                <router-link to="/person" className="hover:text-red-500 h-fit">Personen</router-link> -->
            </span>
    </header>
</template>

<script>

export default {
    name: "EditorHeader.vue",

}
</script>

<style scoped></style>